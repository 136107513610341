html {
  font-family: Roboto, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
footer a, footer a:visited, footer a:link {
  color: lightblue;
  text-decoration: none;
}
header, footer {
  background: #977390;
}